#splash-screen {
    width: 100vw;
    height: 100vh;
    background-color: #404040;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#splash-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#splash-logo {
    width: 100px;
}

#splash-loader {
    margin: 20px 0;
}
