
.list {
    height: 70%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list li {
    list-style-type: none;
    width: 300px;
    height: 300px;
    position: absolute;
    /*left: 50%;*/
    /*margin-left: -150px;*/
    /*background: white;*/
    /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    /*border-radius: 20px;*/
    transition: transform 1s, opacity 1s;
}

.list .act {
    z-index: 10;
    opacity: 1;
}

.list .prev,
.list .next {
    cursor: pointer;
}

.list .prev-1 {
    transform: translateX(-540px) scale(.80);
}

.list .prev-2 {
    transform: translateX(-285px) scale(.80);
}

.list .next-2 {
    transform: translateX(285px) scale(.80);
}

.list .next-1 {
    transform: translateX(540px) scale(.80);
}

.list .hide {
    transform: translateX(-820px) scale(.80);
}

.list .new-next {
    transform: translateX(820px) scale(.80);
}

.list .hide,
.list .new-next {
    opacity: 0;
    transition: opacity .5s, transform .5s;
}

.slider-card {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 20px rgb(0 0 0 / 42%);
    border-radius: 25px;
    background: white;
}

.slider-card-item {
    /*width: 70%*/
    height: 70%;
}

/* SMALL LAPTOP - MD : [900, 1200) */
@media screen and (min-width: 900px) and (max-width: 1199px) {
    .next-1, .prev-1 {
        opacity: 0;
        transition: opacity .5s, transform .5s;
    }
}

/* TABLET - SM : [600, 900) */
@media screen and (min-width: 600px) and (max-width: 899px) {
    .list li {
        /*margin-left: -125px;*/
        width: 250px;
        height: 250px;
    }

    .list .prev-1 {
        transform: translateX(-490px) scale(.80);
    }

    .list .prev-2 {
        transform: translateX(-235px) scale(.80);
    }

    .list .next-2 {
        transform: translateX(235px) scale(.80);
    }

    .list .next-1 {
        transform: translateX(490px) scale(.80);
    }

    .list .hide {
        transform: translateX(-770px) scale(.80);
    }

    .list .new-next {
        transform: translateX(820px) scale(.80);
    }
}
